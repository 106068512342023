<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      userinfo: [],
      ismobile: false,
      orderpage: 1,
      orderarray: [],
      devicespage: 1,
      devicesearch: "",
      ordersearch: "",
      orderpaystatus: -1,
      orderpaytype: -1,
      ordertimes: [],
      allcount: 0,
      pickerOptions: {},
      datacount: 0,
      allpage: 0,
      activeapp: [],
      block: false,
      loading: true,
      empty: true,
      limit: 10,
      systemtype: 0,
      ordersettingdialog: false,
      ordersettingvalue: {},
      autorefreshdata: {
        status: false,
        task: "",
      },
      timer: 60,
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("user.detail.orderlogs");
    that.items = [
      {
        text: that.$t("menuitems.user.list"),
        href: "/user/list",
      },
      {
        text: that.$t("menuitems.user.userdetail"),
        href: "/user/detail?id=" + that.$route.query.id,
      },
      {
        text: that.$t("user.detail.orderlogs"),
        active: true,
      },
    ];
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth();
    let dd = new Date().getDate();
    mm = mm + 1;
    dd = dd - 1;
    let nextday = dd + 1;
    that.ordertimes = [yy + "/" + mm + "/" + dd, yy + "/" + mm + "/" + nextday];
    that.pickerOptions = {
      shortcuts: [
        {
          text: that.$t("device.orders.oneday"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: that.$t("device.orders.oneweek"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: that.$t("device.orders.onemonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: that.$t("device.orders.threemonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    };
    that.getorderlist();
    that.getsysteminfo();
  },
  computed: {
    searchinorderlist() {
      var paycode = {
        wxpay: 3,
        alipay: 2,
      };
      var datas = this.orderarray.filter((value) => {
        return (
          !this.ordersearch ||
          value.ED_id.toLowerCase().includes(
            this.ordersearch.trim().toLowerCase()
          ) ||
          !this.ordersearch ||
          value.ED_name.toLowerCase().includes(
            this.ordersearch.trim().toLowerCase()
          ) ||
          !this.ordersearch ||
          value.sn.toLowerCase().includes(this.ordersearch.trim().toLowerCase())
        );
      });
      if (this.orderpaystatus >= 0) {
        if (
          this.orderpaystatus == 0 ||
          this.orderpaystatus == 1 ||
          this.orderpaystatus == 3
        ) {
          datas = datas.filter((value) => {
            return value.status == this.orderpaystatus;
          });
        }
      }
      if (this.orderpaytype >= 0) {
        datas = datas.filter((value) => {
          return value.payway == this.orderpaytype;
        });
      }
      return datas;
    },
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getorderlist: function () {
      var that = this;
      that.orderarray = [];
      var date = that.ordertimes[0] + "," + that.ordertimes[1];
      that.orderlistloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "orderlist",
            uid: that.$route.query.id,
            page: that.orderpage,
            date: date,
            limit: that.limit,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          var data = response.data;
          if (data.count > 0) {
            that.orderarray = data.data;
            that.datacount = data.count;
            that.allpage = data.allpage;
            that.allcount = data.total;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadexcel: function () {
      var that = this;
      that.orderarray = [];
      var date = that.ordertimes[0] + "," + that.ordertimes[1];
      that.orderlistloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "dump_orderlist",
            uid: that.$route.query.id,
            date: date,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          var data = response.data;
          window.open("/" + data.url);
          that.getorderlist();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    logdata: function (route, id) {
      var that = this;
      if (route == "order") {
        that.$toast.loading({
          message: that.$t("global.loading.readingv2"),
          forbidClick: true,
        });
        that.$axios
          .get("https://doc.aic.pet/" + id + ".orderlog")
          .then((response) => {
            that.$toast.clear();
            window.location.href = response.data.file;
          });
      }
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getorderlist();
    },
    handleCurrentChange(val) {
      this.orderpage = val;
      this.getorderlist();
    },
    deletes(type, id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deleteordertip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "delete",
                type: type,
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getorderlist();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    autorefresh(status) {
      if (status) {
        this.autorefreshdata.task = setInterval(() => {
          this.getorderlist();
        }, this.timer * 1000);
      } else {
        clearInterval(this.autorefreshdata.task);
      }
    },
  },
  filters: {
    formatTimer: function (value) {
      value = value * 1000;
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" v-loading="loading">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.ordertime")
                  }}</label>
                  <div class="row">
                    <el-date-picker
                      v-model="ordertimes"
                      type="daterange"
                      align="right"
                      unlink-panels
                      format="yyyy/MM/dd"
                      value-format="yyyy/MM/dd"
                      :range-separator="$t('device.orders.to')"
                      :start-placeholder="$t('device.orders.begindate')"
                      :end-placeholder="$t('device.orders.enddate')"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.orderstatus")
                  }}</label>
                  <div class="row">
                    <select
                      name="paystatus"
                      class="form-control"
                      v-model="orderpaystatus"
                    >
                      <option value="-1">
                        {{ $t("device.orders.allstatus") }}
                      </option>
                      <option value="0">{{ $t("device.orders.nopay") }}</option>
                      <option value="1">
                        {{ $t("device.orders.orderpay") }}
                      </option>
                      <option value="3">
                        {{ $t("device.orders.refuntrue") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.paytype")
                  }}</label>
                  <div class="row">
                    <select
                      name="paytype"
                      class="form-control"
                      v-model="orderpaytype"
                    >
                      <option value="-1">
                        {{ $t("device.orders.allstatus") }}
                      </option>
                      <option value="0">
                        {{ $t("device.orders.coinpay") }}
                      </option>
                      <option value="1">
                        {{ $t("device.orders.useramount") }}
                      </option>
                      <option value="2">
                        {{ $t("device.orders.alipay") }}
                      </option>
                      <option value="3">{{ $t("device.orders.wxpay") }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.action")
                  }}</label>
                  <div class="row">
                    <el-button
                      type="primary"
                      round
                      @click="getorderlist()"
                      icon="el-icon-search"
                      >{{ $t("device.lists.search") }}</el-button
                    >
                    <el-button
                      round
                      @click="downloadexcel()"
                      icon="el-icon-s-unfold"
                      >{{ $t("device.lists.excel") }}</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <label for="orderdays">
                    {{ $t("device.orders.autorefresh") }}
                  </label>
                  <div class="row">
                    <el-switch
                      v-model="autorefreshdata.status"
                      :inactive-text="$t('global.close')"
                      :active-text="$t('global.open')"
                      @change="autorefresh"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label for="keyword">{{ $t("device.orders.keyword") }}</label>
                  <div class="row">
                    <input
                      type="text"
                      class="form-control"
                      v-model="ordersearch"
                      :placeholder="$t('device.orders.searchorder')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="overflow-x: auto; overflow-y: hidden">
            <table id="deviceslist_table" class="table table-striped">
              <thead>
                <tr>
                  <th class="sortStyle">{{ $t("device.orders.orderid") }}</th>
                  <th class="sortStyle" v-if="systemtype == 0">
                    {{ $t("device.orders.goodsname") }}
                  </th>
                  <th class="sortStyle">
                    {{ $t("device.orders.salsevalues") }}
                  </th>
                  <th class="sortStyle">
                    {{ $t("device.orders.orderprice") }}
                  </th>
                  <th class="sortStyle" v-if="systemtype == 0">
                    {{ $t("device.orders.paytype") }}
                  </th>
                  <th class="sortStyle" v-if="systemtype != 1">
                    {{ $t("device.orders.orderstatus") }}
                  </th>
                  <th class="sortStyle" v-if="systemtype == 2">
                    {{ $t("device.orders.user") }}
                  </th>
                  <th class="sortStyle">{{ $t("device.orders.ordertime") }}</th>
                  <th class="sortStyle">{{ $t("device.orders.deviceid") }}</th>
                  <th class="sortStyle">{{ $t("device.orders.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!searchinorderlist || orderarray.length == 0">
                  <td colspan="9">
                    <el-empty
                      :description="$t('device.orders.nodata')"
                    ></el-empty>
                  </td>
                </tr>
                <tr
                  v-for="(val, idx) in searchinorderlist"
                  :class="'device' + val.id"
                  :key="val.id"
                >
                  <td>
                    <span :id="val.sn">{{ val.shid }}</span>
                    <b-tooltip :target="val.sn">{{ val.sn }}</b-tooltip>
                  </td>
                  <td v-if="systemtype == 0 && val.goods">{{ val.goods }}</td>
                  <td v-if="systemtype == 0 && !val.goods">
                    {{ $t("device.orders.nogoodsname") }}
                  </td>

                  <td>
                    {{ val.goodstotal }}
                    <span v-if="val.ED_id.substring(0, 4) == 7710">
                      {{ $t("device.orders.pack") }}</span
                    >
                    <span v-else>ML</span>
                  </td>
                  <td>
                    {{ val.total
                    }}<span v-if="val.refun_fee > 0"
                      >({{ $t("device.orders.status.refund_price") }}:{{
                        val.refun_fee
                      }})</span
                    >
                  </td>
                  <td v-if="systemtype == 0">
                    <span v-if="val.payway == 0"
                      ><i class="ri-wallet-line"></i
                      >{{ $t("device.orders.payment.cash") }}</span
                    >
                    <span v-if="val.payway == 1"
                      ><i class="ri-wallet-line"></i
                      >{{ $t("device.orders.payment.wallet") }}</span
                    >
                    <span v-if="val.payway == 2"
                      ><i class="fab fa-alipay" style="color: #4dabf7"></i
                      >{{ $t("device.orders.payment.alipay") }}</span
                    >
                    <span v-if="val.payway == 3"
                      ><i class="fab fa-weixin" style="color: #3cb034"></i
                      >{{ $t("device.orders.payment.wxpay") }}</span
                    >
                    <span v-if="val.payway == 4"
                      ><i class="fab fa-apple-pay"></i
                      >{{ $t("device.orders.payment.applepay") }}</span
                    >
                    <span v-if="val.payway == 5"
                      ><i class="fab fa-google-wallet"></i
                      >{{ $t("device.orders.payment.googlewallet") }}</span
                    >
                    <span v-if="val.payway == 6"
                      ><i class="fab fa-amazon-pay"></i
                      >{{ $t("device.orders.payment.amazonpay") }}</span
                    >
                    <span v-if="val.payway == 7"
                      ><i class="fab fa-qq"></i
                      >{{ $t("device.orders.payment.qpay") }}</span
                    >
                    <span v-if="val.payway == 40"
                      ><i class="fab fa-bestpay"></i
                      >{{ $t("device.orders.payment.bestpay") }}</span
                    >
                    <span v-if="val.payway == 41"
                      ><i class="fab fa-sandpay"></i
                      >{{ $t("device.orders.payment.sandpay") }}</span
                    >
                  </td>
                  <td v-if="systemtype != 1">
                    <label
                      class="badge badge-secondary"
                      v-if="val.paytime > 0 && val.refun_fee == 0"
                      >{{ $t("device.orders.status.orderpay") }}</label
                    >
                    <label class="badge badge-danger" v-if="val.paytime == 0">{{
                      $t("device.orders.status.nopay")
                    }}</label>
                    <label
                      class="badge badge-danger"
                      v-if="val.feedback == 6 && systemtype != 2"
                      >{{ $t("device.orders.status.devicedown") }}</label
                    >
                    <label
                      class="badge badge-danger"
                      v-if="val.feedback == 2 && systemtype != 2"
                      >{{ $t("device.orders.status.returnfalse") }}</label
                    >
                    <label class="badge badge-info" v-if="val.feedback == 3">{{
                      $t("device.orders.status.beersurpgoods")
                    }}</label>
                    <label
                      class="badge badge-warning"
                      v-if="
                        val.feedback == 7 &&
                        val.paytime > 0 &&
                        val.refun_fee == 0 &&
                        systemtype != 2
                      "
                      >{{ $t("device.orders.status.serversenddone") }}</label
                    >
                    <label
                      class="badge badge-success"
                      v-if="
                        val.feedback == 1 &&
                        val.paytime > 0 &&
                        val.refun_fee == 0 &&
                        systemtype != 2
                      "
                      >{{ $t("device.orders.status.pushdone") }}</label
                    >
                    <!--例外-->
                    <label
                      class="badge badge-success"
                      v-if="
                        val.feedback == 3 &&
                        val.paytime > 0 &&
                        val.refun_fee == 0 &&
                        val.ED_id == 750004340001
                      "
                      >{{ $t("device.orders.status.pushdone") }}</label
                    >

                    <label
                      class="badge badge-warning"
                      v-if="val.refun_fee > 0"
                      >{{ $t("device.orders.status.refuntrue") }}</label
                    >
                  </td>
                  <td v-if="systemtype == 2">
                    <span v-if="val.uid > 0 && val.user">{{ val.user }}</span>
                    <span v-else-if="val.uid > 0 && !val.user">{{
                      $t("device.order.usernotfound")
                    }}</span>
                    <span v-else>
                      <span v-if="val.user">{{
                        $t("device.order.grouppay", { group: val.user })
                      }}</span>
                      <span v-else>
                        <span v-if="val.payway == 0"
                          ><i class="ri-wallet-line"></i
                          >{{ $t("device.orders.payment.cash") }}</span
                        >
                        <span v-if="val.payway == 1"
                          ><i class="ri-wallet-line"></i
                          >{{ $t("device.orders.payment.wallet") }}</span
                        >
                        <span v-if="val.payway == 2"
                          ><i class="fab fa-alipay" style="color: #4dabf7"></i
                          >{{ $t("device.orders.payment.alipay") }}</span
                        >
                        <span v-if="val.payway == 3"
                          ><i class="fab fa-weixin" style="color: #3cb034"></i
                          >{{ $t("device.orders.payment.wxpay") }}</span
                        >
                        <span v-if="val.payway == 4"
                          ><i class="fab fa-apple-pay"></i
                          >{{ $t("device.orders.payment.applepay") }}</span
                        >
                        <span v-if="val.payway == 5"
                          ><i class="fab fa-google-wallet"></i
                          >{{ $t("device.orders.payment.googlewallet") }}</span
                        >
                        <span v-if="val.payway == 6"
                          ><i class="fab fa-amazon-pay"></i
                          >{{ $t("device.orders.payment.amazonpay") }}</span
                        >
                        <span v-if="val.payway == 7"
                          ><i class="fab fa-qq"></i
                          >{{ $t("device.orders.payment.qpay") }}</span
                        >
                        <span v-if="val.payway == 40"
                          ><i class="fab fa-bestpay"></i
                          >{{ $t("device.orders.payment.bestpay") }}</span
                        >
                        <span v-if="val.payway == 41"
                          ><i class="fab fa-sandpay"></i
                          >{{ $t("device.orders.payment.sandpay") }}</span
                        >
                      </span>
                    </span>
                  </td>
                  <td v-if="val.paytime > 0">
                    {{ val.paytime | formatTimer }}
                  </td>
                  <td v-if="val.paytime == 0">
                    {{ val.addtime | formatTimer }}
                  </td>
                  <td>
                    <span :id="'orderid' + idx">{{ val.ED_id }}</span>
                    <b-tooltip :target="'orderid' + idx">{{
                      val.ED_name
                    }}</b-tooltip>
                  </td>
                  <td>
                    <router-link
                      :to="{
                        path: '/device/orderdetail',
                        query: { id: val.id },
                      }"
                    >
                      <a class="btn btn-info rounded-pill">
                        {{ $t("device.orders.button.info") }}
                      </a>
                    </router-link>
                    <a
                      class="btn btn-dark rounded-pill"
                      @click="deletes('order', val.id)"
                      v-if="!block"
                    >
                      {{ $t("device.orders.button.delete") }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="allcount"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
</style>